<div class="card mb-3 all">
  <div class="row g-0">
    <div class="col-md-3">



      <img src="{{
        servico.ImagemPrincipal != null && servico.ImagemPrincipal != ''
          ? apiLocale + servico.ImagemPrincipal
          : 'assets/logo/logo-nova-colorida.svg'
      }}" class="img-fluid rounded-start" alt="background image">



    </div>
    <div class="col-md-6">
      <div class="card-body">
        <!-- <h5 class="card-title">{{ servico.Titulo }}</h5> -->
        <div class="row mb-3 col-9 card-body" [ngSwitch]="servico.CategoriaServico">
          <h5 class="fonte-cabecalho-card"> {{servico.Titulo}}
              <span *ngSwitchCase="0">({{'REGULAR' | translate}})</span>
              <span *ngSwitchCase="1">({{'PRIVATIVO' | translate}})</span>
              <span *ngSwitchCase="2">({{'LUXO' | translate}})</span>
          </h5>
      </div>
        <div>
          <button class="btn btn-success" (click)="abrirModal(contentServicoDetalhes, servico)" *ngIf="!buscando">{{"Detalhes do Serviço" | translate}}</button>
        </div>
      </div>
    </div>
    <div class="col-md-3 d-flex flex-column justify-content-around align-items-center">
      <p class="card-subtitle"><small class="text-muted">{{"A partir de" | translate}}:</small></p>
      <h5 class="card-title">{{servico.MoedaListagem.Simbolo}} {{ servico.ValorListagem | currency:'BRL':'':'2.2-2' }}</h5>

      <label class="btn btn-success" *ngIf="!servico.selecionado">
        <input type="checkbox" class="checked-btn" (click)="selecionou(servico)" /><span>{{"Adicionar" | translate}}</span>
      </label>
      <label class="btn btn-outline-success" *ngIf="servico.selecionado">
        <input type="checkbox" class="checked-btn" (click)="removeu(servico)"/><span>{{"Remover" | translate}}</span>
      </label>
    </div>
  </div>
</div>

<ng-template #contentServicoDetalhes let-modal>
  <app-servico-detalhes [objServico]="objServico"></app-servico-detalhes>
</ng-template>
