import { Alteracoes } from './../base';
import { FormaPagamento } from '../gerenciamento/forma-pagamento.model';

export class ReservaPagamento {
  //  Reserva : any
  // Moeda : any
  FormaPagamento: FormaPagamento;
  Alteracoes: Alteracoes;
  Id: number;
  Guid: number;
  Valor: number;
  Status: number;
  MoedaId: number;
  ReservaId: number;
  PaxPaganteId: number;
  TipoPagamento: number;
  CondicaoPagamentoId: number;
  Success: string;
  Erros: any;
  PaxNaoPagantes: any;
  formaSelecionada: any;
  FormaSelecionada: any;
  Financiamentos: any[];
  CondicaoPagamento: any;
  Quitada: boolean;
  Processado = false;
    Taxas?: number;
}

export class Financiamento {
  Id: number;
  ReservaId: number;
  Cep: any;
  Erros: any;
  Bairro: any;
  Status: any;
  CidadeDs: any;
  EstadoDs: any;
  profissao: any;
  Profissao: any;
  Logradouro: any;
  Complemento: any;
  DataNascimento: any;
  ProfissaoCodigo: any;
}

export class ReservaPessoa {
  sexo = '';
  Pax: string;
  Id: number;
  PaxPaganteId: number;
  Nascimento: any = null;
  IdadeCertaVoltaViagem: boolean;
}

export class Reserva {
  Pessoas: ReservaPessoa[];
  Id: number;
  ValorTotal: number;
  ValorTotalComissao: number;
  Agencia: any;
  Descontos: any[] = [];
  DescontoAplicado: any;
  ValorDesconto: any;
  ValorDescontoId: any;
  AereoConsolidacao: any;
    TaxaTotal?: any;
    ValorPagoTaxas?: any;
    TaxaTotalMkp?: any;
    ValorPagoTaxasMkp?: any;
    ValorMkpTaxa?: any;
}

export class FiltroReserva {
  id: number;
  pax: string;
  localizador: string;
  situacao = -1;
  AgenciaId: any;
  SomenteComReservas = false;
  Data1: any;
  Data2: any;
}

export enum STATUSRESERVA {
  // TODAS = -1,
  PENDENTE = 0,
  AGUARDANDOINTEGRADOR = 1,
  PARCIALMENTECONFIRMADO = 2,
  RESERVADO = 3,
  CONFIRMADO = 4,
  EMITIDO = 5,
  PAGO = 6,
  PROBLEMATECNICO = 7,
  CANCELADA = 8,
  EXCLUIDA = 9,
}
