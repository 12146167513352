import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CidadeService, AeroportoService, LoginService, PaisService } from 'src/app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { Busca, Aeroporto, Quarto, Trecho, Cidade } from 'src/app/model';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';

// tslint:disable: variable-name
// tslint:disable: max-line-length

@Component({
  selector: 'app-busca',
  templateUrl: './busca.component.html',
  styleUrls: ['./busca.component.css']
})
export class BuscaComponent implements OnInit, AfterViewInit {

  constructor(
    private _router: Router,
    public route: ActivatedRoute,
    protected cidadeService: CidadeService,
    private aeroportoService: AeroportoService,
    private loginService: LoginService,
    public titleService: Title,
    protected paisService: PaisService,
  ) { }

  busca: Busca = new Busca();
  temagencia: any;
  buscaValidator: any;
  tipoBuscaAereo: string;
  qntTrechos = 0;
  cidades = [];
  rebusca = false;
  adicionar = false;
  bloqueado = false;
  id = null;
  usuario = null;
  timerCidade = null;

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    if (this.id !== undefined) {
      this.adicionar = true;
    } else {
      this.adicionar = false;
    }
    this.busca.trechos = this.busca.trechos.map(x => {
      x.dataIda = moment(x.dataIda);
      x.dataVolta = moment(x.dataVolta);
      x.minDataIda = moment(x.minDataIda);
      x.minDataVolta = moment(x.minDataVolta);
      return x;
    });
    this.usuario = this.loginService.user();

    this.aereoConsolidacao();
  }

  ngAfterViewInit() {

    const busca = window.sessionStorage.getItem('busca');
    if (busca !== null) {
      this.busca = this.busca.decodeBusca(busca);

      this.busca.trechos = this.busca.trechos.map(x => {
        x.dataIda = moment(x.dataIda);
        x.dataVolta = moment(x.dataVolta);
        x.minDataIda = moment(x.minDataIda);
        x.minDataVolta = moment(x.minDataVolta);
        return x;
      });
    }
    this.busca.destinoCodigo = '';
  }

  aereoConsolidacao() {
    if (this.id === undefined && this.busca.tipoBusca.aereo && (!this.busca.tipoBusca.hotel)) {
      this.busca.AereoConsolidacao = true;

    } else {
      this.busca.AereoConsolidacao = false;
    }
  }

  selecionaTab(tipo: string) {
    switch (tipo) {
      case 'aereo':
        this.busca.tipoBusca.aereo = !this.busca.tipoBusca.aereo;
        break;
      case 'hotel':
        this.busca.tipoBusca.hotel = !this.busca.tipoBusca.hotel;
        break;
    }

    this.aereoConsolidacao();
  }

  buscar() {
    this.busca.guid = null;
    this.busca.primeira = true;
    this.busca.novoOrcamento = true;


    this.id = this.route.snapshot.params.id;
    if (this.id !== undefined) {
      this.adicionar = true;
    }

    this.busca.ReservaId = this.id;


    this.busca.orcamentoId = null;
    this.busca.usuario = this.usuario;
    this.busca.PacoteId = 0;
    if (this.busca.tipoBusca.aereo || this.busca.tipoBusca.hotel) {
      this.busca.destinoCodigo = '';
    }

    if (this.usuario.AgenciaId) {
      this.busca.AgenciaId = this.usuario.AgenciaId;

    } else {
      let agencia = localStorage.getItem('g8h7d8');

      if (agencia != null) {
        const agenciaID = JSON.parse(atob(agencia));
        this.busca.AgenciaId = agenciaID.Id;
      }
    }

    window.sessionStorage.removeItem('busca');
    window.sessionStorage.setItem('busca', JSON.stringify(this.busca));
    window.sessionStorage.removeItem('orcamentousuario');

    if (this.busca.ReservaId > 0) {
      if (this.busca.tipoBusca.aereo) {
        this._router.navigate([`adicionar/${this.busca.ReservaId}/aereo`]);
      } else if (this.busca.tipoBusca.hotel) {
        this._router.navigate([`adicionar/${this.busca.ReservaId}/hotel`]);
      } else if (this.busca.tipoBusca.ticket) {
        this._router.navigate([`adicionar/${this.busca.ReservaId}/servico`]);
      }

    } else {

      if (this.busca.tipoBusca.aereo) {
        this._router.navigate([`listas/aereo`]);
      } else if (this.busca.tipoBusca.hotel) {
        this._router.navigate([`listas/hotel`]);
      } else if (this.busca.tipoBusca.ticket) {
        this._router.navigate([`listas/servico`]);
      }
    }
  }

  verificaTab() {
    return (
      this.busca.tipoBusca.aereo ||
      this.busca.tipoBusca.hotel ||
      this.busca.tipoBusca.ticket
    );
  }

  buscaCidade(val: string) {
    if (this.busca.tipoBusca.aereo) {
      this.aeroportoService.buscarPorParametro(val, 1).subscribe(
        dados => {
          '';
          this.cidades = dados.Items.map(x => {
            x.display_field = `${x.Iata} - ${x.Descricao} / ${x.Cidade.Nome} - ${x.Cidade.Estado.Iata} / ${x.Cidade.Estado.Pais.Iata}`;
            return x;
          });
        },
        erro => {
          console.log(erro);
          this.cidades = [];
        }
      );
    } else {
      this.cidadeService.buscarOrcamento(val, 1).subscribe(
        dados => {
          '';
          this.cidades = dados.Items.map(x => {
            x.display_field = x.Nome + ' - ' + x.Estado.Nome + ' / ' + x.Estado.Pais.Nome;
            return x;
          });
        },
        erro => {
          console.log(erro);
          this.cidades = [];
        }
      );
    }
  }

  buscaCidadeDebounce(val: string) {
    clearTimeout(this.timerCidade);
    this.timerCidade = setTimeout(() => {
      this.buscaDados(val);
    }, 600);
  }

  buscaDados(val: string) {
    if (this.busca.tipoBusca.aereo) {
      this.aeroportoService.buscarPorParametro(val, 1).subscribe(
        dados => {
          this.cidades = dados.Items.map(x => {
            x.display_field = `${x.Iata} - ${x.Descricao} / ${x.Cidade.Nome} - ${x.Cidade.Estado.Iata} / ${x.Cidade.Estado.Pais.Iata}`;
            return x;
          });
        },
        erro => {
          console.log(erro);
          this.cidades = [];
        }
      );
    } else {
      this.cidadeService.buscarOrcamento(val, 1).subscribe(
        dados => {
          this.cidades = dados.Items.map(x => {
            x.display_field = x.Nome + ' - ' + x.Estado.Nome + ' / ' + x.Estado.Pais.Nome;
            return x;
          });
        },
        erro => {
          console.log(erro);
          this.cidades = [];
        }
      );
    }
  }

  selecionaCidadeOrigem(item, trecho) {
    if (this.busca.tipoBusca.aereo) {
      trecho.AeroportoPartida = item;
      trecho.cidadeOrigem = item.Cidade;
    } else {
      trecho.cidadeOrigem = item;
    }
  }

  selecionaCidadeDestino(item, trecho) {
    if (this.busca.tipoBusca.aereo) {
      trecho.AeroportoChegada = item;
      trecho.cidadeDestino = item.Cidade;
      trecho.cidadeDestino.display_field =
        trecho.cidadeDestino.Nome +
        ' - ' +
        trecho.cidadeDestino.Estado.Nome +
        ' / ' +
        trecho.cidadeDestino.Estado.Pais.Nome;
    } else {
      trecho.cidadeDestino = item;
    }
  }

  adicionarTrecho() {
    this.busca.trechos.push(new Trecho());
    this.qntTrechos++;
  }

  removerTrecho(indice) {
    this.qntTrechos--;

    if (indice === undefined) {
      this.busca.trechos.pop();
    } else {
      this.busca.trechos.splice(indice, 1);
    }
  }

  setUmTrecho() {
    while (this.busca.trechos.length > 1) {
      this.busca.trechos.pop();
    }
    this.busca.trechos[0].cidadeOrigem = new Cidade();
    this.busca.trechos[0].cidadeDestino = new Cidade();
    this.busca.trechos[0].AeroportoPartida = new Aeroporto();
    this.busca.trechos[0].AeroportoChegada = new Aeroporto();
  }

  setDataIda(event, trechos, indice) {

    trechos[indice].minDataVolta = moment(trechos[indice].dataIda).add(1, 'days').format('YYYY-MM-DD');
    if (this.busca.nrnoites !== null && this.busca.nrnoites !== 0) {
      trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
        this.busca.nrnoites,
        'days'
      ).format('YYYY-MM-DD');
    } else {
      trechos[indice].dataVolta = trechos[indice].minDataVolta;
    }

    if (indice > 0) {
      trechos[indice].minDataIda = moment(trechos[indice].dataIda).add(1, 'days').format('YYYY-MM-DD');
    }

    for (let i = indice + 1; i < trechos.length; i++) {
      if (i === 1) {
        trechos[i].minDataIda = moment(trechos[indice].dataIda).add(1, 'days').format('YYYY-MM-DD');
      }

      if (trechos[i].minDataIda > trechos[i].dataIda) {
        trechos[i].dataIda = trechos[i].minDataIda;
      }
    }
    this.GetDateDiffIda(event, trechos, indice);
  }


  onChangeNoites(event, trechos, indice) {
    trechos[indice].dataVolta = moment(trechos[indice].dataIda).add(
      this.busca.nrnoites,
      'days'
    ).format('YYYY-MM-DD');
  }

  GetDateDiffIda(event, trechos, indice) {
    if (trechos[indice].dataVolta != null) {
      const diffdays = moment(trechos[indice].dataVolta).diff(event, 'days');
      this.busca.nrnoites = diffdays;
    }
  }

  onChangeVolta(event, trechos, indice) {
    this.GetDateDiffVolta(event, trechos, indice);
  }

  GetDateDiffVolta(event, trechos, indice) {
    if (trechos[indice].dataVolta != null) {
      event = moment(event);
      const diffdays = event.diff(trechos[indice].dataIda, 'days');
      this.busca.nrnoites = diffdays;
    }
  }

  incrementaQuartos() {
    this.busca.quartos.push(new Quarto());
  }

  decrementaQuartos() {
    this.busca.quartos.pop();
  }
}
