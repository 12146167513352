import { Component, EventEmitter, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { API, BACKGROUNDIMAGE } from 'src/app/app.config';

import { Busca, BuscaServicoRQ } from 'src/app/model';

@Component({
  selector: 'app-servico',
  templateUrl: './servico.component.html',
  styleUrls: ['./servico.component.scss']
})

export class ServicoComponent implements OnInit {

  @Input() servico: any;
  @Input() usuario: any;
  // @Input() busca: Busca;
  // @Input() buscaRQ: BuscaServicoRQ;
  @Input() selecionar = true;
  @Input() buscando = true;
  @Output() selecionouServico = new EventEmitter<any>();
  @Output() removeuServico = new EventEmitter<any>();

  backgroundImage = BACKGROUNDIMAGE;
  objServico: any;
  apiLocale = API;

  diasDaSemana = [
    { diaSemana: 'Dom' },
    { diaSemana: 'Seg' },
    { diaSemana: 'Ter' },
    { diaSemana: 'Qua' },
    { diaSemana: 'Qui' },
    { diaSemana: 'Sex' },
    { diaSemana: 'Sáb' }
  ]
  servicoCardLoadded: boolean;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.servicoCardLoadded = false;

  }

  selecionou(servico: any) {
    this.selecionouServico.emit(servico);
  }

  removeu(servico: any) {
    this.removeuServico.emit(servico);
  }

  // carregar(obj) {
  //   this.servicoCardLoadded = true;
  //   this.objServico = obj;

  //   if (this.isJson(this.objServico.ExtraIntegracao)) {
  //       this.objServico.ExtraIntegracao2 = JSON.parse(this.objServico.ExtraIntegracao);
  //   }
  // }

  abrirModal(content, servico) {

    this.objServico = servico;
    if (this.isJson(this.objServico.ExtraIntegracao)) {
      this.objServico.ExtraIntegracao2 = JSON.parse(this.objServico.ExtraIntegracao);
    }
    
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
