import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hotel-quartos',
  templateUrl: './hotel-quartos.component.html',
  styleUrls: ['./hotel-quartos.component.scss']
})
export class HotelQuartosComponent implements OnInit {

  @Input()
  Hotel: any;
  @Input()
  pacote:Boolean = false;

  @Output() selecionouHotel = new EventEmitter<any>();

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  fecharModal(){
    this.modalService.dismissAll();
  }

  selecionou() {
    this.selecionouHotel.emit(this.Hotel);
    this.fecharModal();
  }

}
