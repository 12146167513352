import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { SERVICOS_OBRIGATORIOS } from 'src/app/app.config';
import { Busca, PacoteReserva } from 'src/app/model';
import { LoginService } from 'src/app/services';

@Component({
  selector: 'app-pacote-servico',
  templateUrl: './pacote-servico.component.html',
  styleUrls: ['./pacote-servico.component.scss']
})
export class PacoteServicoComponent implements OnInit {

  @Output() selecionou = new EventEmitter<any>();
  @Output() removeu = new EventEmitter<any>();
  @Output() avancar = new EventEmitter<any>();
  @Input() Orcamento: PacoteReserva[];

  buscando: boolean = false;
  servicos: any;
  slider: any;
  usuario: any;
  objModal: any;
  pacoteReserva: any = new PacoteReserva();

  servicosObrigatoriosPacote: boolean = SERVICOS_OBRIGATORIOS;

  constructor(private modalService: NgbModal, private loginService: LoginService) { }

  ngOnInit() {
    let reserva = window.sessionStorage.getItem('pacoteReserva');
    let pacoteReservaStorage = JSON.parse(reserva);
    this.servicos = pacoteReservaStorage.Servicos;
    this.Orcamento[0].Servicos = [];
    this.selecionou.emit(this.Orcamento);
    this.pacoteReserva = this.Orcamento[0];
    this.slider = this.Orcamento[0].Pacote.PacoteImagens[0];
    this.usuario = this.loginService.user();

    if (this.servicosObrigatoriosPacote) {
      this.servicos.forEach(x => this.selecionarServico(x));
    };

  }

  servicoSelecionado(servico) {
    const dado = this.Orcamento[0].Servicos.filter(x => x.Id == servico.Id);
    return dado.length > 0;
  }

  rotaAvancar() {
    this.avancar.emit();
  }

  selecionarServico(servico) {
    servico.selecionado = true;
    this.Orcamento[0].Servicos.push(servico);
    this.selecionou.emit(this.Orcamento);
  }

  removerServico(servico) {
    servico.selecionado = false;
    let indice = -1;
    for (let i = 0; i < this.Orcamento[0].Servicos.length; i++) {
      if (this.Orcamento[0].Servicos[i].Id == servico.Id) {
        indice = i;
      }
    }
    if (indice >= 0) {
      this.Orcamento[0].Servicos.splice(indice, 1);
      this.removeu.emit(servico);
    }
  }

  abrirModal(content, servico) {
    this.objModal = servico;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  temServico() {
    if (this.Orcamento[0]?.Servicos.filter(x => x.selecionado == true).length > 0) {
      return true;
    } else {
      return false;
    }
  }

}
