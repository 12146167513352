<div class="container">
  <div class="filters">
    <h4>{{"Filtrar e Ordenar" | translate }}</h4>
    <div class="input">
      <h6>{{"Pesquisar" | translate}}</h6>
      <input id="nome_Hotel_Search" [(ngModel)]="FiltroHotel.Nome" value="" type="text" class="form-control" placeholder="Nome do Hotel">
    </div>
    <div class="filto-hotel mt-4">
      <div class="rating-css">
        <p>{{"Classificação" | translate}}</p>
        <form class="form-inline slider-classificacao">
          <ng5-slider [(value)]="FiltroHotel.EstrelasMinimo" [(highValue)]="FiltroHotel.EstrelasMaximo" [options]="OpcoesClassificacao" (userChangeEnd)="buscarDebounce()"></ng5-slider>
        </form>
      </div>
      <div class="faixa-preco-hotel mt-2">
        <p>Preço</p>
        <form class="form-inline">
          <ng5-slider [(value)]="FiltroHotel.ValorMinimo" [(highValue)]="FiltroHotel.ValorMaximo" [options]="OpcoesValor" (userChangeEnd)="buscarDebounce()"></ng5-slider>
        </form>
      </div>
      <div class="faixa-preco-hotel mt-2" *ngIf="busca.usuario.EmpresaId != null">
        <p>{{"Fornecedores" | translate}}</p>
        <div *ngFor="let fornecedor of Fornecedores; let k = index">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="fornecedor-{{fornecedor}}" name="fornecedor-{{fornecedor}}" (change)="selecionarFornecedor(fornecedor)" [checked]="FornecedorSelecionado(fornecedor)" />
            <label class="form-check-label" for="fornecedor-{{fornecedor}}">{{fornecedor}} </label>
          </div>
        </div>
      </div>
      <div class="faixa-preco-hotel mt-2">
        <p>{{"Pensões" | translate}}</p>
        <div *ngFor="let pensao of FiltroHotel.Pensoes; let k = index">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="pensao-{{pensao}}" name="pensao-{{pensao}}" (change)="selecionarPensao(pensao)" [checked]="pensaoSelecionada(pensao)" />
            <label class="form-check-label" for="pensao-{{pensao}}">{{pensao}} </label>
          </div>
        </div>
      </div>
      <div class="filterButton">
        <button (click)="refazerBusca(busca)" type="button" class="btn btn-success">{{"Filtrar" | translate}}</button>
      </div>
    </div>
  </div>
  <div class="resultList">
    <div *ngIf="carregando">
      <app-view-loading></app-view-loading>
    </div>
    <div *ngIf="buscando">
      <div class="row">
        <ng-container *ngFor="let integrador of buscasAtivas">
          <div class="col col-md-3" style="vertical-align: middle;display:inline-flex">
            <p>{{ integrador }}</p>
            <img src="assets/img/loading1.gif" />
          </div>
        </ng-container >
      </div>
    </div>
    <div class='row nav-options' [hidden]="buscando || (Orcamento.length == 0)">
      <div class='col text-end'>
        <button [disabled]="buscando || (Orcamento.length == 0)" class="btn btn-success" (click)="rotaAvancar()">
          <a>{{"Avançar" | translate}}<i class="fas fa-chevron-right"></i></a>
        </button>
      </div>
    </div>
    <div *ngIf="!carregando">
      <div>



        <div class="theme-search-results-item _mb-10 theme-search-results-item-rounded theme-search-results-item-" *ngFor="let hotel of hoteis, let i = index">

          <div class="hotel-card">
            <div class="image">
              <img class="card-img-top" src="{{ hotel.HotelImagem != null && hotel.HotelImagem != '' ? hotel.HotelImagem : backgroundimage }}">
            </div>
            <div class="details">
              <div class='row'>
                <div class='col'>
                  <h4 *ngIf="busca.usuario.EmpresaId != (undefined || null)">{{ hotel.Nome  + " - (" + hotel.Fornecedor + ")" }}</h4>
                  <h4 *ngIf="busca.usuario.EmpresaId == (undefined || null)">{{ hotel.Nome  }}</h4>
                </div>
              </div>
              <div class='row '>
                <div class="stars">
                  <app-estrelas [estrela]="hotel.Estrelas"></app-estrelas>
                </div>
              </div>
              <div class='row '>
                <div class='col'>
                  <p>{{ hotel.Endereco }}</p>
                </div>
              </div>
              <div class='row'>
                <div class='col'>
                  <div class='row'>
                    <div class='col'>
                      <div>
                        <button class="btn btn-success" style="margin-top: 10px" (click)="abrirModal(contentHotelDetails, hotel)"> {{"Detalhes" | translate }} </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
            <div class="preco">
              <div class='row text-center'>
                <div class='col'>
                  {{"A partir de" | translate}}
                </div>
              </div>
          
              <div class='row text-center '>
                <div class='col'>
                  <h4>{{hotel.Quartos[0].MoedaListagem.Simbolo}} {{ somaTotal(hotel.Quartos) | currency:'BRL':'':'2.2-2' }}</h4>
                </div>
              </div>
          
              <div class='row mb-3'>
                <div class='col d-flex content-center'>
                  <a class="btn btn-success" (click)="abrirModal(contentHotelQuartos, hotel)">{{"Selecionar" | translate}} <span *ngIf="hotel.QuantidadeSelecionada > 0">({{hotel.QuantidadeSelecionada}})</span></a>
                </div>
              </div>
              <div *ngIf="hotel.selecionado" class='row'>
                <div class='col d-flex content-center' id="remover">
                  <!-- <a class="btn btn-block btn-outline-success" data-bs-toggle="modal" data-bs-target="#removerHotel" (click)="carregar(hotel)">Remover</a> -->
                  <a class="btn btn-block btn-outline-success" (click)="removerHotel(hotel)">{{"Remover" | translate}}</a>
                </div>
              </div>
            </div>
          </div>
          <!-- <app-hotel [buscando]="buscando" [hotel]="hotel" [usuario]="busca.usuario" (selecionouHotel)="selecionarHotel($event)" (removeuHotel)="removerHotel($event)"></app-hotel> -->
        </div>



        <p *ngIf="hoteis.length == 0 && !buscando">{{"Esta busca não retornou nenhum resultado." | translate}}</p>
        <div class="justify-content-center col-md-12 col-lg-12 col-xxxl-12">
          <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarPagina($event)"></app-paginacao>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #contentHotelQuartos let-modal>
  <app-hotel-quartos [Hotel]="hotelDetails" (selecionouHotel)="selecionarHotel($event)"></app-hotel-quartos>
</ng-template>

<ng-template #contentHotelDetails let-modal>
  <app-hotel-detalhes [hotel]="hotelDetails"></app-hotel-detalhes>
</ng-template>
