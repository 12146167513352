import { Component, Input, OnInit } from '@angular/core';
import { BACKGROUNDIMAGE } from 'src/app/app.config';

export const ORCAMENTO = 'orcamentousuario';
@Component({
  selector: 'app-orcamento-hotel-card',
  templateUrl: './orcamento-hotel-card.component.html',
  styleUrls: ['./orcamento-hotel-card.component.scss']
})
export class OrcamentoHotelCardComponent implements OnInit {

  @Input() index: any;
  @Input() hotel: any;
  @Input() busca: any;
  @Input() Pacote: Boolean = false;

  orcamento: any;
  Arr = Array;
  dif: number;
  user: any;
  hotelCardLoadded: boolean;
  objeto: any;
  backgroundimage = BACKGROUNDIMAGE;
  orcamentoId: any;

  constructor() { }

  ngOnInit() {
    this.hotelCardLoadded = false
    this.user = JSON.parse(localStorage.getItem('loggedUser'));
    this.dif = Math.floor(this.hotel.Estrelas);

    if (this.Pacote == false) {
      let orcamento = JSON.parse(sessionStorage.getItem(ORCAMENTO));
      this.orcamento = orcamento;
      const busca = JSON.parse(sessionStorage.getItem('busca'));
      this.orcamentoId = busca.orcamentoId;

      if (this.orcamentoId) {
        this.hotel.Opcao = 1;
      }
    }
    else {

    }

  }
  somaTotal(arr) {
    return arr
      .map(x => x.ValorListagem)
      // tslint:disable: only-arrow-functions
      .reduce(function (a: any, b: any) {
        return a + b;
      }, 0);
  }
  carregar(obj) {
    this.objeto = obj;
  }
}
