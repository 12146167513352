<div class="container">
  <div class="filters">
    <h4>{{"Filtrar e Ordenar" | translate }}</h4>
    <div class="price">
      <h5 class="mt-3">{{"Preço" | translate}}</h5>
      <ng5-slider [(highValue)]="FiltroAereo.ValorMaximo" [options]="OpcoesValor" (userChangeEnd)="buscarDebounce()"></ng5-slider>
      <h5 class="mt-3">Faixas de Horário</h5>
      <h6 class="mt-3">Ida</h6>
      <ng5-slider [(value)]="FiltroAereo.HorarioIdaMinimo" [(highValue)]="FiltroAereo.HorarioIdaMaximo" [options]="OpcoesHorario" (userChangeEnd)="buscarDebounce()"></ng5-slider>
      <h6 class="mt-3">Volta</h6>
      <ng5-slider [(value)]="FiltroAereo.HorarioVoltaMinimo" [(highValue)]="FiltroAereo.HorarioVoltaMaximo" [options]="OpcoesHorario" (userChangeEnd)="buscarDebounce()"></ng5-slider>
      <h5 class="mt-3">Duração</h5>
      <div *ngFor="let o of OpcoesDuracaoTrechos; let i = index">
        <h6>Trecho {{ (i+1) }}</h6>
          <ng5-slider [(value)]="FiltroAereo.MinimoDuracaoTrechos[i]" [options]="o"  [(highValue)]="FiltroAereo.MaximoDuracaoTrechos[i]" (userChangeEnd)="buscarDebounce()"></ng5-slider>
      </div>
      <h6 class="mt-3">Número do Paradas - Ida</h6>
      <form>
        <select class="form-select" placeholder="Selecione" [(ngModel)]="FiltroAereo.NumeroParadasIda" (ngModelChange)="buscarDebounce()" name="bagagem">
          <option value="-1">{{"Todos" | translate }}</option>
          <option value="0">{{"Direto" | translate}}</option>
          <option value="1">1 {{"escala/conexão" | translate}} </option>
          <option value="2">2 {{"escalas/conexões" | translate}}</option>
          <option value="3">3 {{"escalas/conexões" | translate}}</option>
        </select>
      </form>
      <h6 class="mt-3">{{"Número do Paradas" | translate}} - {{"Volta" | translate}}</h6>
      <form>
        <select class="form-select" placeholder="Selecione" [(ngModel)]="FiltroAereo.NumeroParadasVolta" (ngModelChange)="buscarDebounce()" name="bagagem">
          <option value="-1">{{"Todos" | translate}}</option>
          <option value="0">{{"Direto" | translate}}</option>
          <option value="1">1 {{"escala/conexão" | translate}}</option>
          <option value="2">2 {{"escalas/conexões" | translate}}</option>
          <option value="3">3 {{"ou mais escalas/conexões" | translate}}</option>
        </select>
      </form>

      <h6 class="mt-3">Número do {{"voo" | translate}}</h6>
      <form class="form-inline">
                        <tag-input [(ngModel)]="Voos" secondaryPlaceholder="Número do Voo" maxItems="6"
                          name="Voos"></tag-input>
                      </form>
      <!-- <input id="nVoo_Search" [(ngModel)]="Voos" value="" type="text" class="form-control" placeholder="Número do voo"> -->
      <div class="mt-4" *ngIf="busca.usuario.EmpresaId != null">
        <h6>{{"Cias" | translate}}</h6>

        <div *ngFor="let cia of Cias; let k = index">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="cia-{{cia}}" name="cia-{{cia}}" (change)="selecionarCia(cia)" [checked]="ciaSelecionada(cia)" />
            <img style="max-width: 60px" src="assets/img/cias/{{cia}}.png" title="{{cia}}">
          </div>

        </div>
      </div>

      <div class=" mt-4" *ngIf="busca.usuario.EmpresaId != null">
        <h6>{{"Fornecedores" | translate}}</h6>

        <div *ngFor="let fornecedor of Fornecedores; let k = index">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="fornecedor-{{fornecedor}}" name="fornecedor-{{fornecedor}}" (change)="selecionarFornecedor(fornecedor)" [checked]="FornecedorSelecionado(fornecedor)" />
            <img style="max-width: 60px" src="assets/img/cias/{{fornecedor}}.png" title="{{fornecedor}}">
          </div>

        </div>
      </div>


      <div class="bagagem">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text mx-0" for="inputGroupSelect01">{{"Bagagem" | translate}}</label>
          </div>
          <select [(ngModel)]="FiltroAereo.Bagagem" class="custom-select" id="inputGroupSelect01">
            <option class="dropdown-item" *ngFor="let i of [].constructor(5), let x = index">{{ x }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="filterButton">
      <button (click)="refazerBusca(busca)" type="button" class="btn btn-success">{{"Filtrar" | translate}}</button>
    </div>
  </div>

  <div class="resultList">
    <div class="alert alert-danger" role="alert" *ngIf="busca.trechos[0].AeroportoChegada.Cidade.Nome == (null || undefined || '')">
      {{"Revise os dados da busca e realize a rebusca!" | translate}}
    </div>

    <div *ngIf="buscando">
      <div class="row">
        <ng-container *ngFor="let integrador of buscasAtivas">
          <div class="col col-md-3" style="vertical-align: middle;display:inline-flex">
            <p>{{ integrador }}</p>
            <img src="assets/img/loading1.gif" />
          </div>
        </ng-container >
      </div>
    </div>

    <div class='row nav-options' [hidden]="buscando || (Orcamento.length == 0)" *ngIf="busca.ReservaId > 0">
      <div class='col-4 btn-avancar'>
        <button [disabled]="buscando || (Orcamento.length == 0)" class="btn btn-success" (click)="rotaAvancar()">
          <a>Avançar <i class="fas fa-chevron-right"></i></a>
        </button>
      </div>
    </div>
    <div *ngIf="!buscando">
      <div *ngFor="let aereo of aereos">
        <app-aereo [aereo]="aereo" [usuario]="busca.usuario" (selecionouAereo)="selecionarAereo($event)" (removeuAereo)="removerAereo($event)"></app-aereo>
      </div>
      <p *ngIf="(aereos.length === 0 && !buscando)">{{"Esta busca não retornou nenhum resultado." | translate}}</p>
      <div class="justify-content-center col-md-12 col-lg-12 col-xxxl-12">
        <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarPagina($event)"></app-paginacao>
      </div>
    </div>
  </div>
</div>
